import React from 'react';
import catImg from '../img/cute-cat-illustration-cat-kawaii-chibi-drawing-style-cat-cartoon-vector copy.png'
import { Link } from 'react-router-dom';
import logo from '../img/logo.png'
import { motion } from "framer-motion"
import { Carousel } from 'react-responsive-carousel';
import axios  from 'axios';
import {useQuery} from "@tanstack/react-query"
import { FaCircle } from "react-icons/fa";
import Skeleton from 'react-loading-skeleton';

const Hero = () => {
  const getSingleProduct = async () => {
    const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/invoice/top-selling-products?shopId=${process.env.REACT_APP_SHOP_ID}`);
    return data;
  }
  
  const {data:products, isLoading, isFetching, isError} = useQuery(
    {
      queryKey: ["top-selling-product"],
      queryFn: getSingleProduct,
    }
  )

  if (isLoading) {
    return (
       <section className="bg-third h-[800px] bg-no-repeat bg-cover bg-center py-24">
        <div className="container mx-auto flex justify-around h-full">
          <div className="flex flex-col justify-center">
            <div className="font-semibold flex items-center uppercase">
              <motion.div animate={{ x: 0 }} transition={{ type: "spring", stiffness: 100 }}>New Trend</motion.div>
            </div>
            <span className="text-[70px] leading-[1.1] font-light mb-4">NEW DISCOUNT SALES</span>
            <div>
              <a href="#product" className="px-6 py-3 rounded-md text-sm bg-fourth text-white">Shop Now</a>
            </div>
          </div>
          <div className="overflow-hidden flex justify-center items-center lg:w-1/2 relative">
            <img src={catImg} className="hidden drop-shadow-2xl lg:block w-[500px] z-10 absolute" alt="" />
            <div className=" w-1/2 aspect-square inset-0 z-0 rounded-full absolute">
              <img src={logo} alt="" />
            </div>
          </div>
        </div>
    </section>
    )
  }


  return (
  // <section className="bg-third h-[800px] bg-no-repeat bg-cover bg-center py-24">
  //   <div className="container mx-auto flex justify-around h-full">
  //     <div className="flex flex-col justify-center">
  //       <div className="font-semibold flex items-center uppercase">
  //         <motion.div animate={{ x: 0 }} transition={{ type: "spring", stiffness: 100 }}>New Trend</motion.div>
  //       </div>
  //       <span className="text-[70px] leading-[1.1] font-light mb-4">NEW DISCOUNT SALES</span>
  //       <div>
  //         <a href="#product" className="px-6 py-3 rounded-md text-sm bg-fourth text-white">Shop Now</a>
  //       </div>
  //     </div>
  //     <div className="overflow-hidden flex justify-center items-center lg:w-1/2 relative">
  //       <img src={catImg} className="hidden drop-shadow-2xl lg:block w-[500px] z-10 absolute" alt="" />
  //       <div className=" w-1/2 aspect-square inset-0 z-0 rounded-full absolute">
  //         <img src={logo} alt="" />
  //       </div>
  //     </div>
  //   </div>
  // </section>
    <Carousel showStatus={false} swipeScrollTolerance={30} showThumbs={false} interval={5000} autoPlay infiniteLoop > 
      {products?.length > 0 && products.map((product,index)=>(
          <section className="bg-third h-[800px] bg-no-repeat bg-cover bg-center py-24">
          <div className="container mx-auto flex flex-col-reverse md:flex-row justify-around h-full">
            <div className="flex flex-col justify-center">
              <motion.div key={product.id} // Add key here
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: false }}
                 transition={{ duration: 0.5 }}
                 variants={{
                   visible: { opacity: 1, x: 0 },
                   hidden: { opacity: 0, x: -100 }
                 }} className="font-semibold flex items-center uppercase">
                <motion.div animate={{ x: 0 }} transition={{ type: "spring", stiffness: 100 }}><span className="text-fourth">#</span>{index + 1} best selling</motion.div>
              </motion.div>
              <motion.span 
                 key={product.id} // Add key here
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: false }}
                 transition={{ duration: 0.5 }}
                 variants={{
                   visible: { opacity: 1, x: 0 },
                   hidden: { opacity: 0, x: 100 }
                 }}
               className="text-5xl lg:text-[70px] text-left leading-[1.1] font-light mb-4">{product.name}</motion.span>
              <div className="flex">
                <motion.a key={product.id} // Add key here
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: false }}
                 transition={{ duration: 0.5 }}
                 variants={{
                   visible: { opacity: 1, y: 0 },
                   hidden: { opacity: 0, y: 100 }
                 }} href="#product" className="px-6 py-3 self-start rounded-md text-sm bg-fourth text-white">Shop Now</motion.a>
              </div>
            </div>
            <div className="overflow-hidden flex justify-center items-center lg:w-1/2 relative">
              <motion.img key={product.id} // Add key here
                 initial="hidden"
                 whileInView="visible"
                 viewport={{ once: false }}
                 transition={{ duration: 0.5 }}
                 variants={{
                   visible: { opacity: 1, x: 0 },
                   hidden: { opacity: 0, x: 100 }
                 }} src={product.images[0]} className="drop-shadow-2xl w-[500px] z-10 lg:absolute" alt="" />
              <div className=" w-1/2 aspect-square inset-0 z-0 rounded-full lg:absolute">
                <img src={logo} alt="" />
              </div>
            </div>
          </div>
        </section>
      ))}
  </Carousel>
)};

export default Hero;

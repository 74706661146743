import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
//pages import
import Home from './pages/Home'
import ProductDetails from './pages/ProductDetails'
//component import
import SideBars from './components/Sidebar'
import Header from './components/Header'
import Footer from './components/Footer'
import toast, { Toaster } from 'react-hot-toast';
import { CartContext } from './contexts/CartContext';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  
  const {status} = useContext(CartContext)
  if(status){
    toast.success(status);
  }

  const queryClient = new QueryClient();

  return <div className='overflow-hidden'>
    <Router>
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <Toaster />
        <Header />
        <Routes>
          <Route path='/' element={<Home />}/>
          <Route path='/product/:id' element={<ProductDetails />} />
        </Routes>
        <SideBars />
        <Footer />
      </QueryClientProvider>
    </Router>
  </div>;
};

export default App;

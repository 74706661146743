import React from 'react';
//import link
import { Link } from 'react-router-dom';
//import icons
import {IoMdArrowForward} from 'react-icons/io'
import {FiTrash2} from 'react-icons/fi'
//import components
import CartItem from '../components/CartItem'
//import sidebar context
import { SidebarContext } from '../contexts/SidebarContext';
//import cart context
import { CartContext } from '../contexts/CartContext';
import { useContext } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { TbBrandInstagram } from "react-icons/tb";
import { TbBrandTelegram } from "react-icons/tb";

const Sidebar = () => {
  const { isOpen, handleClose} = useContext(SidebarContext)
  const {cart,clearCart,total} = useContext(CartContext);
  // console.log(useContext(CartContext));
  // const {} = useContext(CartContext);
  return <div className={`${isOpen? 'right-0' : '-right-full' } w-full bg-white fixed top-0 h-screen shadow-2xl md:w-[35vw] xl:max-w-w[30vw] transition-all duration-300 z-[100] px-4 lg:px-[35px]`}>

    <div className="flex items-center justify-between py-6 border-b">
      <div className='uppercase text-sm font-semibold'>Shopping Cart ({cart && cart.length})</div>
      <div className='cursor-pointer w-8 h-8 flex justify-center items-center' onClick={handleClose}><IoMdArrowForward className='text-2xl'/></div>
    </div>
    <div className="flex-1 h-[66vh] overflow-x-hidden overflow-y-auto">
      {cart.map((item)=>{
        return(<CartItem item={item} key={item.id} />)
      })}
    </div>
    <div className='flex flex-col gap-y-3 py-4 mt-4 self-end'>
      <div className='flex w-full justify-between items-center'>
      {/* total */}
        <div className='uppercase font-semibold '>
          <span className='mr-2'>Total:</span> ${parseFloat(total).toFixed(2)}
        </div>
        {/* clear cart */}
        <div onClick={clearCart} className='cursor-pointer rounded-lg py-4 bg-fourth text-white w-12 h-12 flex justify-center items-center text-xl'>
          <FiTrash2 />
        </div>
      </div>
      <Menu as="div" className="relative inline-block text-right">
                <div>
                  <Menu.Button className="px-2 py-3 w-full cursor-pointer rounded-md text-xs bg-fourth text-white">
                    Buy Now
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-[100] left-26 bottom-12 mt-2 w-56 origin-bottom-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="https://t.me/Sinish_Ta"
                            className={`${
                              active ? 'bg-fourth text-white' : 'text-gray-900'
                            } group flex w-full gap-1 items-center rounded-md px-2 py-2 text-sm`}
                          >
                            {active ? (
                              <EditActiveIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <EditInactiveIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                            Telegram
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="https://www.instagram.com/sinishta_shop?igsh=MWR3NnJ5cDd0OXEwbA=="
                            className={`${
                              active ? 'bg-fourth text-white' : 'text-gray-900'
                            } group flex w-full gap-1 items-center rounded-md px-2 py-2 text-sm`}
                          >
                            {active ? (
                              <DuplicateActiveIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <DuplicateInactiveIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                            Instagram
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                    
              
                  </Menu.Items>
                </Transition>
              </Menu>
    </div>
  </div>;
};

export default Sidebar;


function EditInactiveIcon(props) {
  return (
    <TbBrandTelegram />
  )
}

function EditActiveIcon(props) {
  return (
    <TbBrandTelegram />
  )
}

function DuplicateInactiveIcon(props) {
  return (
    <TbBrandInstagram />
  )
}

function DuplicateActiveIcon(props) {
  return (
    <TbBrandInstagram />
  )
}




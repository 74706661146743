import React,{createContext, useState, useEffect} from 'react';
import {Toast} from 'react-hot-toast'

//create context
export const CartContext = createContext();

const CartProvider = ({children}) => {
  const [cart, setCart] = useState([]);
  //item amount state
  const [itemAmount, setItemAmount] = useState(0);
  const [total, setTotal] = useState();
  const [status, setStatus] = useState(null);

  useEffect(()=>{
    if(cart){
      const amount = cart.reduce((accumulator, item) => (
        accumulator + item.amount
      ),0)
      setItemAmount(amount);
    }
    const total = cart.reduce((accumulator,currentItem)=>(
      accumulator + currentItem.price * currentItem.amount
    ),0)
      setTotal(total);
  },[cart])
  

  const addToCart = (id, product)=>{
    try{
      const newItem = {...product, amount : 1}
    //find that specific product
    const cartItem = cart.find((item)=>{
      return item.id === id;
    })

    //if the item is already exist in the cart
    if (cartItem){
      const newCart = [...cart].map((item)=>{
        if (item.id === id){
          return {...item, amount: cartItem.amount + 1};
        }else{
          return item;
        }
      }); 
      setCart(newCart);
    }else{
      setCart([...cart, newItem]);
    }
    setStatus('successfully added to cart');
    }catch(err){
      console.log(err)
    }
  };
  //remove from cart
  const removeFromCart = (id) =>{
    const newCart = cart.filter((item) =>{
      return item.id !== id;
    });
    setCart(newCart);
    setStatus('successfully removed from cart');
  };

  //clear cart
  const clearCart = ()=>{
    setCart([]);
    setStatus('cart is cleared');
  }

  //increasing the amount
  const increaseAmount = (id) => {
    const cartItem = cart.find(item => item.id === id);
    if(cartItem){
      const newCart = cart.map((item) =>{
        if(item.id === id){
          return {...item, amount: cartItem.amount + 1};
        }else{
          return item;
        }
      });
      setCart(newCart);
    }
    // addToCart(cartItem, id);
  }

  //decreasing the amount
  const decreaseAmount = (id) =>{
    const cartItem = cart.find((item)=>{
      return item.id === id;
    });
    if(cartItem){
      const newCart = cart.map((item) =>{
        if(item.id === id){
          return {...item, amount: cartItem.amount - 1};
        }else{
          return item;
        }
      });
      setCart(newCart);
    }
      if (cartItem.amount < 2){
        removeFromCart(id);
      }
  }
  
  return <CartContext.Provider value={{cart,addToCart,removeFromCart,clearCart,increaseAmount,decreaseAmount,total,itemAmount,status, setStatus}}>{children}</CartContext.Provider>;
};

export default CartProvider;

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {IoMdAdd, IoMdClose, IoMdRemove} from 'react-icons/io'
import { CartContext } from '../contexts/CartContext';

const CartItem = ({item}) => {
  const {removeFromCart,increaseAmount,decreaseAmount}= useContext(CartContext)
  //item destructuring
  const {id, name, images, price, amount} = item;
  return (
      <div className='flex gap-x-4 py-2 lg:px-6 border-b border-gray-200 w-full font-light'>
        <div className='w-full min-h-[150px] flex items-center gap-x-4'>
          <Link to={`/product/${id}`}>
            <img className='max-w-[4rem]' src={images[0].imageUrl} alt="" />
          </Link>
          <div className='w-full flex flex-col'>
            <div className='flex justify-between mb-2'>
              <Link to={`/product/${id}`} className='text-sm uppercase font-medium hover:text-secondary hover:underline transition'>{name}</Link>
              <div onClick={()=>removeFromCart(id)} className='text-xl cursor-pointer'><IoMdClose className='text-gray-500 hover:text-secondary transition'/></div>
            </div>
            <div className='rounded flex items-center gap-x-2 h-[36px]'>
              {/* qty */}
              <div className='flex flex-1 max-w-[100px] items-center justify-center h-full border text-primary font-medium'>
              {/* MINUS ICON */}
                <div onClick={()=>{decreaseAmount(id)}} className='flex-1 flex justify-center items-center cursor-pointer h-full'>
                  <IoMdRemove />
                </div>
                {/* AMOUNT */}
                <div className='h-full flex justify-center items-center px-2'>{amount}</div>
              {/* ADD ICON */}
                <div onClick={()=>increaseAmount(id)} className='flex-1 h-full flex justify-center cursor-pointer items-center'>
                  <IoMdAdd />
                </div>
              </div>
              {/* item price */}
              <div className='flex-1 flex items-center justify-around text-gray-500'>$ {price}</div>
              {/* final price */}
              <div className='flex-1 flex justify-end items-center text-primary font-medium'>{`$ ${parseFloat(price * amount).toFixed(2)}`}</div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default CartItem;

import React,{useContext} from 'react';
import { ProductContext } from '../contexts/ProductContext';
import Product from '../components/Product'
import Hero from "../components/Hero"
import Skeleton from 'react-loading-skeleton'
import { Link } from 'react-router-dom';
import {motion} from "framer-motion"
import {BsPlus, BsEyesFill, BsEyeFill} from "react-icons/bs"

const Home = () => {
  //get products from product context
  const {products,loading} = useContext(ProductContext);
  console.log(products);
  // get only men's clothes and women's clothing category
  const filteredProducts = products.filter(item => {
    // return item.category === "men's clothing" || item.category === "women's clothing";
    return item;
  });

  const variant = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };
  
  return <div className="">
    <Hero />
    <section id="product" className="py-16">
      <div className="container mx-auto">
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-[30px] mx-auto md:max-w-none md:mx-0">
          {loading?[...Array(10)].map((id)=>(
                <div>
                <div className="border border-[#e4e4e4] rounded-lg h-[300px] mb-4 relative overflow-hidden group transition">
                  <Skeleton containerClassName="flex-1" className="w-full h-full" />
                  <div className="w-full h-full flex justify-center items-center">               
                    {/* button */}
                    <div className="absolute top-0 right-0 bg-secondary p-2 flex flex-col items-center justify-center gap-y-2 opacity-0 group-hover:opacity-100 transition-all">
                      <button>
                        <div className="flex justify-center items-center text-white w-12 h-12">
                          <Skeleton />
                          <BsPlus className="text-3xl" />
                        </div>
                      </button>
                      <Link to={`/product/${id}`} className="w-12 h-12 bg-white flex justify-center items-center text-primary drop-shadow-xl rounded-md">
                        <BsEyeFill />
                      </Link>
                    </div>
                  </div> 
                </div>
                {/* category and title and price */}
                <div>
                  <div className='text-sm capitalize text-gray-500'><Skeleton /></div>
                  <Link className="font-semibold mb-1 hover:text-secondary hover:underline" to={`product/`}>
                    <Skeleton />
                  </Link>
                  <div className='font-semibold'><span className='text-secondary flex'> </span><Skeleton className="w-1/2" /></div>
                </div>
              </div>
          )):filteredProducts.map((product,index) => {
            return (<motion.span variants={variant} initial="hidden" animate="visible" transition={{
              delay: index * 0.25,
              ease: "easeInOut",
              duration: 0.5,
            }}>
              <Product key={product.id} index={index} product={product}/>
            </motion.span>);
          })}
        </div>
      </div>
    </section>
  </div>;
};

export default Home;

import React from 'react';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import {ProductContext} from '../contexts/ProductContext'
import {CartContext} from '../contexts/CartContext'
import axios  from 'axios';
import {useQuery} from "@tanstack/react-query"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {motion} from "framer-motion"
import { Link } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { TbBrandInstagram } from "react-icons/tb";
import { TbBrandTelegram } from "react-icons/tb";





const ProductDetails = () => {
  const {id} = useParams(); 
  const {addToCart} = useContext(CartContext);
  let [searchParams, setSearchParams] = useSearchParams();
  const params = new URLSearchParams();

  const getSingleProduct = async () => {
    const {data} = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/product/${id}?shopId=${process.env.REACT_APP_SHOP_ID}`);
    return data;
  }

  const {data:product, isLoading, isFetching, isError} = useQuery(
    {
      queryKey: ["single-product"],
      queryFn: getSingleProduct,
    }
  )

  console.log(product)



  console.log(isError)

  if(isLoading || isFetching){
    return (
      <motion.div initial={{opacity:0}} animate={{ opacity: 100 }} transition={{ ease: "easeInOut", duration: .5 }} className="pt-32 pb-12 lg:py-32 h-screen flex items-center">
      <div className="container mx-auto">
        <div className="grid md:grid-cols-2 md:gap-24 mb-16">
          <div className="flex flex-1 justify-center items-center mb-8 lg:mb-0">
            <Skeleton containerClassName="flex-1" height={400} />
          </div>
          <div className="flex-1 flex flex-col justify-center">
            <h1 className="text-[26px] font-medium mb-2 max-w-[450px] lg:mx-0"><Skeleton containerClassName='flex-1' /></h1>
            <div className="text-xl font-medium">
              <span className="text-fourth flex gap-1"><Skeleton count={2} containerClassName='flex-1' /></span> 
            </div>
            <p className="mb-8"><Skeleton /></p>
            <Skeleton width={100} height={50} containerClassName="" />
          </div>
        </div>
      </div>
    </motion.div>
    )
  }


  return (
    <motion.section initial={{opacity:0}} animate={{ opacity: 100 }} transition={{ ease: "easeInOut", duration: .5 }} className="pt-32 pb-12 mb-16 lg:py-32 h-screen overflow-scroll md:overflow-y-hidden w-full grid justify-center items-center">
      <div className="container mx-auto w-full">
        <div className="grid md:grid-cols-2 md:gap-24">
          <Carousel showThumbs={false} className="mb-14 md:mb-0 lg:scale-90">
            {product.images.length == "1"? 
                <img src={product?.images[0].imageUrl} className="object-cover w-full h-full" alt="" />
              :product.images.map((image)=>(
              <div className="flex flex-1 max-h-[500px] h-auto rounded-lg overflow-hidden justify-center items-center mb-8 lg:mb-0">
                <img src={image.imageUrl} className="object-cover w-full h-full" alt="" />
              </div>
            ))}
          </Carousel>
          <div className="flex-1 flex flex-col gap-1 justify-center lg:px-12">
            <h1 className="text-[26px] font-medium mb-2 max-w-[450px] lg:mx-0">{product?.name.toUpperCase()}</h1>
            <div className="text-xl font-medium">
              <span className="text-fourth">$</span> {searchParams.get("variant_price")?searchParams.get("variant_price"):product?.price}
            </div>
            <div className="flex gap-3 items-center">
              {/* <span className="font-light text-slate-500">product description:</span> */}
              <p className="my-2 text-sm font-extralight text-slate-500">{product?.description}</p>
            </div>

            <div className="flex flex-col justify-center gap-2">
              {/* <span className="font-light text-slate-500">variations:</span> */}
              <div className="flex gap-2">
                {product?.variants?.length === 0 ? <span className="font-extralight text-slate-500 text-sm">No variation is available for this product</span> :product?.variants.map((variant)=>(
                  <span key={variant.id} onClick={()=>{setSearchParams({"variant_id":variant.id,"variant_price":variant.variant_price})}} className={`${variant.id === searchParams.get("variant_id")?'text-fourth':'text-slate-500'} cursor-pointer text-xs border-[1px] bg-white px-4 py-2 rounded-lg`}>{variant.name}</span>
                ))}
              </div>
            </div>
           <div>
            {/* <button onClick={()=> addToCart(product?.id, product)} className="bg-third py-4 px-8 rounded-lg">Add to cart</button> */}
            <div className="flex gap-2 my-6">
              {/* <span onClick={()=> addToCart(product?.id, product)} className="px-6 py-3 cursor-pointer rounded-md text-sm bg-white text-fourth border-[1px]">Add to Cart</span> */}
              {/* <a href="https://www.instagram.com/sinishta_shop?igsh=MWR3NnJ5cDd0OXEwbA==" className="px-6 py-3 cursor-pointer rounded-md text-sm bg-fourth text-white">Buy Now</a> */}
              <div className="w-12 text-center">
              <Menu as="div" className="relative inline-block text-right">
                <div>
                  <Menu.Button className="px-6 py-3 w-28 cursor-pointer rounded-md text-sm bg-fourth text-white">
                    Buy Now
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-26 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="https://t.me/Sinish_Ta"
                            className={`${
                              active ? 'bg-fourth text-white' : 'text-gray-900'
                            } group flex w-full gap-1 items-center rounded-md px-2 py-2 text-sm`}
                          >
                            {active ? (
                              <EditActiveIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <EditInactiveIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                            Telegram
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="https://www.instagram.com/sinishta_shop?igsh=MWR3NnJ5cDd0OXEwbA=="
                            className={`${
                              active ? 'bg-fourth text-white' : 'text-gray-900'
                            } group flex w-full gap-1 items-center rounded-md px-2 py-2 text-sm`}
                          >
                            {active ? (
                              <DuplicateActiveIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <DuplicateInactiveIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                            Instagram
                          </a>
                        )}
                      </Menu.Item>
                    </div>          
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            </div>
           </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default ProductDetails;

function EditInactiveIcon(props) {
  return (
    <TbBrandTelegram />
  )
}

function EditActiveIcon(props) {
  return (
    <TbBrandTelegram />
  )
}

function DuplicateInactiveIcon(props) {
  return (
    <TbBrandInstagram />
  )
}

function DuplicateActiveIcon(props) {
  return (
    <TbBrandInstagram />
  )
}


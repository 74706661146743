import React from 'react';
import { useParams } from 'react-router-dom';


const Footer = () => {
  const {id} = useParams(); 
  return <div className={`bg-white border-t py-12`}>
    <div className="container mx-auto flex flex-col gap-2">
      <p className="text-center">
        Copyright <span className="text-secondary font-semibold">&copy;</span> Sinishta Shop 2024. All rights reserved.
      </p>
      <p className="text-slate-500 text-center">
        Powered by <a href="https://n4k-saas.com" className="text-fourth hover:underline font-medium">n4k-saas</a>
      </p>
    </div>
  </div>;
};

export default Footer;

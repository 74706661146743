import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import {BsPlus, BsEyesFill, BsEyeFill} from "react-icons/bs"
//import cart context
import { CartContext } from '../contexts/CartContext';
import {motion} from "framer-motion"
import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { TbBrandInstagram } from "react-icons/tb";
import { TbBrandTelegram } from "react-icons/tb";



const Product = ({product,index}) => {
  const {addToCart} = useContext(CartContext);
  //product destructuring
  const  {id, images ,category ,price, name} = product;
  return (
    <div>
      <div className="border border-[#e4e4e4] rounded-lg h-[300px] mb-2 relative overflow-hidden group transition">
        <div className="w-full h-full flex justify-center items-center">
          {/* image */}
          <div className="w-[200px] mx-auto flex flex-wrap justify-center items-center">
            <img className="max-h-[160px] group-hover:scale-110 transition duration-300 select-none" src={images[0].imageUrl} alt="" />
          </div>
          {/* button */}
          <div className="absolute top-0 right-0 bg-secondary p-2 flex flex-col items-center justify-center gap-y-2 opacity-0 group-hover:opacity-100 transition-all">
            <button onClick={()=>{addToCart(id,product)}}>
              <div className="flex justify-center items-center text-white w-12 h-12">
                <BsPlus className="text-3xl"/>
              </div>
            </button>
            <Link to={`/product/${id}`} className="w-12 h-12 bg-white flex justify-center items-center text-primary drop-shadow-xl rounded-md">
              <BsEyeFill />
            </Link>
          </div>
        </div> 
      </div>
      {/* category and title and price */}
      <div className="flex flex-col gap-2">
        <div className='text-sm capitalize text-gray-500'>{category}</div>
        <Link className="font-normal mb-1 hover:text-secondary hover:underline" to={`product/${id}`}>
          {name}
        </Link>
        <div className='font-semibold'>
          <span className='text-secondary'>$ <span className="text-black">{price}</span></span>
        </div>
        <div className="w-12 text-center">
              <Menu as="div" className="relative inline-block text-right">
                <div>
                  <Menu.Button className="px-2 py-2 w-20 cursor-pointer rounded-md text-xs bg-fourth text-white">
                    Buy Now
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute z-[100] left-26 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="https://t.me/Sinish_Ta"
                            className={`${
                              active ? 'bg-fourth text-white' : 'text-gray-900'
                            } group flex w-full gap-1 items-center rounded-md px-2 py-2 text-sm`}
                          >
                            {active ? (
                              <EditActiveIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <EditInactiveIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                            Telegram
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="https://www.instagram.com/sinishta_shop?igsh=MWR3NnJ5cDd0OXEwbA=="
                            className={`${
                              active ? 'bg-fourth text-white' : 'text-gray-900'
                            } group flex w-full gap-1 items-center rounded-md px-2 py-2 text-sm`}
                          >
                            {active ? (
                              <DuplicateActiveIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <DuplicateInactiveIcon
                                className="mr-2 h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                            Instagram
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                    
              
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
        
      </div>
      
    </div>
  );
};

export default Product;

function EditInactiveIcon(props) {
  return (
    <TbBrandTelegram />
  )
}

function EditActiveIcon(props) {
  return (
    <TbBrandTelegram />
  )
}

function DuplicateInactiveIcon(props) {
  return (
    <TbBrandInstagram />
  )
}

function DuplicateActiveIcon(props) {
  return (
    <TbBrandInstagram />
  )
}



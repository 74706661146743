import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
//importing sidebar context
import { SidebarContext } from '../contexts/SidebarContext';
//importing icons
import {BsBag} from "react-icons/bs"
import { CartContext } from '../contexts/CartContext';
import { Link } from 'react-router-dom';
import logo from "../img/logo.png"

const Header = () => {

  const [isActive, setIsActive] = useState(false);
  const {isOpen, setIsOpen} = useContext(SidebarContext);
  const {itemAmount} = useContext(CartContext);


  //event listeners
  useEffect(()=>{
    window.addEventListener('scroll', ()=>{
      window.scrollY > 60 ? setIsActive(true) : setIsActive(false);
    })
  })

  return <header className={`${
    isActive?`bg-white py-4 shadow-md`:`bg-none py-6`
  } fixed w-full z-[50] transition-all`}>
    <div className="container flex mx-auto items-center justify-between h-full">
      <Link to="/">
        <div className="flex items-center">
          <img className="w-[40px]" src={logo} alt="" />
          <span className="sacramento-regular text-xl text-center pt-1 font-semibold">Sinishta Shop</span>
        </div>
      </Link>
      <div className='cursor-pointer flex relative' onClick={()=>{ setIsOpen(!isOpen)}}>
        <BsBag className='text-2xl'/>
        <div className="bg-fourth absolute -right-2 -bottom-2 text-[12px] w-[18px] text-white rounded-full flex justify-center items-center">
          {itemAmount}
        </div>
      </div>
    </div>
  </header>;
};

export default Header;

import React,{createContext, useState, useEffect} from 'react';

//creating context :))
export const ProductContext = createContext();


const ProductProvider = ({children}) => {

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);


  //Fetching products
  useEffect(()=>{

    const fetchProducts = async () =>{
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/product/?shopId=${process.env.REACT_APP_SHOP_ID}`);
      const data = await response.json();
      console.log(data)
      setProducts(data);
      setLoading(false);
    };
    fetchProducts();
  },[])

  return <ProductContext.Provider value={{products,loading,setLoading}}>{children}</ProductContext.Provider>;
};

export default ProductProvider;
